import React from 'react';
import closed from './assets/eyeclosed.png';
import open from './assets/eyeopen.png';
import tess from './assets/tess-photo.jpg';
import Header from './components/header/Header';
import { Helmet } from 'react-helmet';
import { scroller } from 'react-scroll';

import * as S from './App.styles';

function App() {
  const scrollTo = () => {
    scroller.scrollTo('ref', {
      duration: 1500,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <S.App>
      <Helmet>
        <title>Tess Redburn Art Consultancy</title>
        <meta
          name="description"
          content="Tess Redburn Art Consultancy - portfoilio reviews, studio management, freelance representation, advisory roles."
        />
        <meta
          name="keywords"
          content="Tess Redburn, art, consultancy, illustration, illustrators, 
          portfoilios, studio, agency, Yinka Ilori Studio, The AOI, UTILE Studio,
          Victoria Topping, UCA Farnham, The Central Illustration Agency, Hoxton Hotel, Puck
          Collective, Created Academy, The Cass School of Art, Anna Higgie,
          Sir Peter Blake, Ogilvy, Fortnum and Mason, Otherway, BBH, Dulwich
          Festival, Echo Brand Design, Breaking Barriers, VCCP, Chickenhouse
          Publishing"
        />
      </Helmet>
      <Header />
      <S.Body>
        <S.BodyInner>
          <S.TextSection>
            <S.Text>
              An experienced art producer, illustration agent and consultant,
              oiling the wheels between commissioners and creatives.
            </S.Text>
            <S.Scroll href="#" onClick={() => scrollTo()}>
              <S.ContactMe>GET IN TOUCH!</S.ContactMe>
            </S.Scroll>
            <S.Text>
              I can help you to navigate the tricky business side of being a
              creative person, as well as assisting brands and organisations to
              work effectively with talented creative people.
            </S.Text>
          </S.TextSection>
          <S.Row>
            <S.Section>
              <S.SubTitle>
                WORKING WITH <br /> ILLUSTRATORS
              </S.SubTitle>
              <S.List>
                <S.ListItem>Artist Management</S.ListItem>
                <S.ListItem>Portfolio Reviews</S.ListItem>
                <S.ListItem>Business Skills training</S.ListItem>
              </S.List>
              <S.ImageWrapper>
                <S.ImageInner>
                  <S.Image src={open} alt="closed" />
                </S.ImageInner>
              </S.ImageWrapper>
            </S.Section>
            <S.Section>
              <S.SubTitle>
                WORKING WITH <br /> ORGANISATIONS
              </S.SubTitle>
              <S.List>
                <S.ListItem>Art Buying and Curation</S.ListItem>
                <S.ListItem>Production</S.ListItem>
                <S.ListItem>Project Management</S.ListItem>
              </S.List>
              <S.ImageWrapper>
                <S.Image src={closed} alt="closed" />
              </S.ImageWrapper>
            </S.Section>
          </S.Row>
          <S.TextSection>
            <S.SubTitle>ABOUT ME</S.SubTitle>
            <S.Text>
              As the senior agent at the Central Illustration Agency for 5
              years, I represented a world class roster of artists working in
              illustration, murals, sculpture and animation. I combine strategic
              business thinking and a passion for branding with a creative eye
              to achieve successful artist and brand partnerships.
            </S.Text>
            <S.Text>
              Some of my friends, clients and partners in crime include Yinka
              Ilori Studio, The AOI, UTILE Studio, Kapitza, Victoria Topping,
              UCA Farnham, The Central Illustration Agency, Hoxton Hotel, Puck
              Collective, James D Wilson, Created Academy, The Cass School of
              Art, Anna Higgie, Sir Peter Blake, Ogilvy, Fortnum and Mason,
              Otherway, BBH, Dulwich Festival, Echo Brand Design, Breaking
              Barriers, VCCP, Chickenhouse Publishing and more.
            </S.Text>
          </S.TextSection>
          <S.TextSection>
            <S.SubTitle>WHAT I CAN DO FOR ILLUSTRATORS</S.SubTitle>
            <S.SubSubTitle>One-to-One Portfolio Reviews</S.SubSubTitle>
            <S.Text>
              An hour long session, looking at the content and presentation of
              your portfolio. Learn how to identify more succesful or commercial
              avenues for your creative practice and discover your personal
              strengths.
            </S.Text>
            <S.Text>
              Sessions are priced at £50 per hour and can be held in person or
              over Skype.
            </S.Text>
            <S.SubSubTitle>Project Negotiation</S.SubSubTitle>
            <S.Text>
              Had an enquiry land in your inbox and have no idea how to price
              it? I can help by discussing the requirements of the project with
              the client, put together a formal quote, negotiate budgets and go
              through any contracts. A bit like an agent.
            </S.Text>
            <S.Text> In return I’d take a 15% commission.</S.Text>
            <S.SubSubTitle>Business Skills Training</S.SubSubTitle>
            <S.Text>
              I am a regular guest lecturer, typically with illustration degree
              courses, teaching students about the wonderful world of licensing,
              contracts, commissioning, self promotion, agents and anything else
              the next gen illustrator needs to be prepared for life as a
              freelancer.
            </S.Text>
          </S.TextSection>
          <S.TextSection>
            <S.SubTitle>Still want to know more?</S.SubTitle>
            <S.List>
              <S.ListItem>
                <S.Link
                  href="https://www.linkedin.com/in/tess-redburn-444b29bb/"
                  target="_blank"
                >
                  LinkedIn
                </S.Link>
              </S.ListItem>
              <S.ListItem>
                <S.Link
                  href="https://www.instagram.com/tessredburn/"
                  target="_blank"
                >
                  Instagram
                </S.Link>
              </S.ListItem>
              <br></br>
              <S.ListItem>
                <S.Link
                  href="https://the-dots.com/users/tess-redburn-540293"
                  target="_blank"
                >
                  The Dots - see projects I'm really proud of.
                </S.Link>
              </S.ListItem>
              <S.ListItem>
                <S.Link href="https://medium.com/@tessredburn" target="_blank">
                  Medium - read articles I've written.
                </S.Link>
              </S.ListItem>
            </S.List>
          </S.TextSection>
          <S.Row>
            <S.Section className="ref">
              <S.Photo src={tess} alt="tess" />
            </S.Section>
            <S.LetsChat>
              <div>
                <S.SubTitle>Let's chat!</S.SubTitle>
              </div>
              <S.EmailLink
                href="mailto:tess@tessredburnconsultancy.co.uk"
                target="_top"
              >
                tess@tessredburnconsultancy.co.uk
              </S.EmailLink>
              <S.LetsChatText>
                I sometimes make my own art, if you're looking for that, go{' '}
                <S.Link href="https://tessredburn.co.uk" target="_blank">
                  HERE.
                </S.Link>
              </S.LetsChatText>
            </S.LetsChat>
          </S.Row>
          <S.TextSection>
            <S.EmptyDiv>
              <S.SmallText>
                ©2019 Tess Redburn. Site built by my boyfriend under duress.
              </S.SmallText>
            </S.EmptyDiv>
          </S.TextSection>
        </S.BodyInner>
      </S.Body>
    </S.App>
  );
}

export default App;
