import styled from 'styled-components';
import { device } from '../../styles/media';

export const HeaderInner = styled.div`
  ${props => props.theme.gridStyles.getContainerStyles()};
`;

export const TextSection = styled.div`
  ${props => props.theme.gridStyles.getColStyles(12, 12, 12)};
  padding-top: 20px;
`;

export const Header = styled.header`
  background-color: ${({ theme: { colors } }) => colors.green}
  min-height: 30vh;
  position: relative;

  @media ${device.md} {
    min-height: 30vh;
  }
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.fonts.title};
  padding: 20px 0;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  margin: 0;
  font-size: 36px;

  @media ${device.md} {
    font-size: 52px;
  }
`;
