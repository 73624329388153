import styled from 'styled-components';
import { device } from './styles/media';

export const App = styled.div`
  color: ${({ theme }) => theme.colors.blue};
`;

export const TextSection = styled.div`
  ${(props) => props.theme.gridStyles.getColStyles(12, 12, 12)};
  padding-top: 20px;
`;

export const Scroll = styled.a`
  text-decoration: none;
`;

export const ContactMe = styled.p`
  font-family: ${(props) => props.theme.fonts.title};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green};
  display: inline-flex;
  font-weight: bold;
  font-size: 28px;
  margin: 0;
  padding: 20px 0 10px;
  &:hover {
    color: ${({ theme }) => theme.colors.blue} !important;
  }
`;

export const Body = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
`;

export const BodyInner = styled.div`
  ${(props) => props.theme.gridStyles.getContainerStyles()};
`;

export const SubTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.title};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.green};
  display: inline-flex;
  font-weight: bold;
  font-size: 28px;
  margin: 0;
  padding: 20px 0 10px;
`;

export const SubSubTitle = styled.h3`
  font-size: 22px;
  padding: 20px 0 10px;
  font-weight: bold;
  margin: 0;
`;

export const Text = styled.p`
  padding: 10px 0;
  font-size: 22px;
  margin: 0;
`;

export const List = styled.ul`
  padding-inline-start: 0;
`;

export const ListItem = styled.li`
  font-size: 22px;
  list-style-type: none;
  padding: 5px 0;
`;

export const ImageWrapper = styled.div`
  max-width: 325px;
  max-height: 325px;
  padding: 40px 0;
  display: block;
  margin: auto;
`;

export const ImageInner = styled.div``;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const Photo = styled.img`
  height: 100%;
  width: 100%;

  padding-top: 20px;
  @media ${device.md} {
    padding-top: 0;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const EmailLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 18px;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const Section = styled.div`
  ${(props) => props.theme.gridStyles.getColStyles(12, 6, 6)};
  padding-top: 20px;
`;

export const LetsChat = styled.div`
  ${(props) => props.theme.gridStyles.getColStyles(12, 6, 6)};
  text-align: left;
  padding-bottom: 20px;
  padding-top: 20px;

  @media ${device.md} {
    padding-left: 20px;
    padding-top: 20px;

    h2 {
      padding-top: 0;
      padding-bottom: 10px;
    }
  }
`;

export const Row = styled.div`
  text-align: center;

  @media ${device.md} {
    padding-top: 20px;
    ${(props) => props.theme.gridStyles.getRowStyles()};
  }
`;

export const EmptyDiv = styled.div`
  min-height: 60px;
  position: relative;
  display: flex;
  justify-content: center;

  @media ${device.md} {
    min-height: 100px;
  }
`;

export const LetsChatText = styled.p`
  padding: 10px 0;
  font-size: 22px;
  margin: 0;
`;

export const SmallText = styled.p`
  font-size: 12px;
  position: absolute;
  bottom: 0;
`;
