import React from "react";

import * as S from "./Header.styles";

export default () => (
  <S.Header>
    <S.HeaderInner>
      <S.TextSection>
        <S.Title>
          Tess Redburn <br></br>Art Consultancy
        </S.Title>
      </S.TextSection>
    </S.HeaderInner>
  </S.Header>
);
